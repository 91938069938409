/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { findBySlug, getContentfulPageSectionListData } from "../helpers"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Button } from "@theme-ui/components"

import CTA from "../components/cta"
import Column from "../components/column"
import Form from "../components/form"
import Container from "../components/container"
import HeadquartersModule from "../components/headquarters-module"
import Page from "../components/page"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"
import theme from "../gatsby-plugin-theme-ui/index"
import NewsletterForm from "../components/newsletter-form"
import { paramsCheck } from "../functions/functions"


const RequestSession = ({ data }) => {
  paramsCheck()
  const [screenWidth, setScreenWidth] = useState("")
  const [showContact, setShowContact] = React.useState(false)
  const mainFormData = getContentfulPageSectionListData({
    sectionList: data.contentfulPage.sectionList,
    sectionSlug: "page-section-request-session-main-form",
  })

  useEffect(() => {
    if (checkIfWindow) {
      setWindowWidth()
    }
  }, [])
  
  const setWindowWidth = () => {
    setScreenWidth(window.screen.availWidth)
  }

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setWindowWidth)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  const renderForm = () => {
    const contactFormBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "request-session-form-module",
    })

    return (
      <Container
        customStyle={{
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        {mainFormData && (
          <Form
            customStyle={{ px: 0 }}
            formData={mainFormData}
            moveToTopOnSubmit={true}
            noSteps={true}
            type="secondary"
            setWidth={(screenWidth >= 768) ? "50%" : "100%"}
          >
            <Row customStyle={{ pb: 4 }}>
              <Column customStyle={{ maxWidth: 500 }}>
                <Text type="h1">{contactFormBladePatternData.title}</Text>
              </Column>
            </Row>
            <Row customStyle={{ pb: 5 }}>
              <Column customStyle={{ maxWidth: 500 }}>
                <Text>
                  {contactFormBladePatternData.description.description}
                </Text>
              </Column>
            </Row>
          </Form>
        )}
      </Container>
    )
  }

  const renderCTAs = () => {
    const contactCtasBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "request-session-ctas",
    }).contentCards
    return (
      <div
        id="custom-efficient-world-class-design"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        <Container>
          {contactCtasBladePatternData && (
            <Row>
              {contactCtasBladePatternData[0] && (
                <Column size={[12, 12, 3]}>
                  <Text type="h3" customStyle={{ mb: theme => [0, 0, 3] }}>
                    {contactCtasBladePatternData[0].title}
                  </Text>
                  <div sx={{ width: "90%" }}>
                    {contactCtasBladePatternData[0].description &&
                      documentToReactComponents(
                        contactCtasBladePatternData[0].description.json,
                        {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                              <Text
                                customStyle={{
                                  mt: 0,
                                }}
                              >
                                {children}
                              </Text>
                            ),
                          },
                        }
                      )}
                    <CTA
                      link={
                        get(
                          contactCtasBladePatternData[0],
                          "ctaDestination.ctaDestination"
                        ) ||
                        get(
                          contactCtasBladePatternData[0],
                          "ctaDestinationExternal.ctaDestinationExternal"
                        ) ||
                        "/"
                      }
                      type="secondary"
                      linkType={
                        get(
                          contactCtasBladePatternData[1],
                          "ctaDestinationExternal.ctaDestinationExternal"
                        )
                          ? "external"
                          : "internal"
                      }
                      customStyle={{
                        mb: ["30px", "30px", 0],
                        mt: 3,
                      }}
                    >
                      {contactCtasBladePatternData[0].ctaTitle}
                    </CTA>
                  </div>
                </Column>
              )}
              {contactCtasBladePatternData[1] && (
                <Column size={[12, 12, 3]}>
                  <Text type="h3" customStyle={{ mb: theme => [0, 0, 3] }}>
                    {contactCtasBladePatternData[1].title}
                  </Text>
                  <div sx={{ width: "90%" }}>
                    {contactCtasBladePatternData[1].description &&
                      documentToReactComponents(
                        contactCtasBladePatternData[1].description.json,
                        {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                              <Text
                                customStyle={{
                                  mb: 10,
                                }}
                              >
                                {children}
                              </Text>
                            ),
                          },
                        }
                      )}
                    <div sx={{ marginBottom: "10px", marginTop: "10px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[1].ctaTitle} >`}
                        linkType={
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </div>
                </Column>
              )}
              <Column size={[12, 12, 3]}>
                {contactCtasBladePatternData[2] && (
                  <React.Fragment>
                    <Text type="h3">
                      {contactCtasBladePatternData[2].title}
                    </Text>
                    <div sx={{ mb: ["10px", 0, 0], marginTop: "15px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[2].ctaTitle} >`}
                        linkType={
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
                {contactCtasBladePatternData[3] && (
                  <React.Fragment>
                    <Text type="h3">
                      {contactCtasBladePatternData[3].title}
                    </Text>
                    <div sx={{ mb: ["10px", 0, 0], marginTop: "15px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[3],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[3],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[3].ctaTitle} >`}
                        type={
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </Column>
              <Column size={[12, 12, 3]}>
                <Text type="h3">Join Our Newsletter</Text>
                <Button
                  sx={{
                    ...theme.forms.buttonChevron,
                    "&::after": {
                      content: "'>'",
                      ml: 10,
                      position: "relative",
                      bottom: "1px",
                    },
                    fontSize: 14,
                    pr: "75px",
                    py: "5px",
                    pl: 19,
                    mt: 26,
                    display: "flex",
                    justifyContent: "space-between",
                    width: 120,
                    color: "primary",
                    border: theme => `2px solid ${theme.colors.primary}`,
                    background: "transparent",
                    whiteSpace: "nowrap",
                    ":hover": {
                      border: theme => `2px solid ${theme.colors.secondary}`,
                      color: "secondary",
                    },
                  }}
                  onClick={() => setShowContact(true)}
                >
                  Signup
                </Button>
              </Column>
            </Row>
          )}
        </Container>
      </div>
    )
  }

  const renderHeadquartersModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "request-session-headquarters-and-factory",
    })
    if (!bladePatternData) return

    return <HeadquartersModule bladePatternData={bladePatternData} />
  }

  return (
    <Page data={data.contentfulPage}>
      {showContact && <NewsletterForm onClose={() => setShowContact(false)} />}
      {checkIfWindow()
        ? renderForm()
        : null}
      {/* {renderForm()} */}
      {/* {renderCTAs()} */}
      {/* {renderHeadquartersModule()} */}
    </Page>
  )
}

export default RequestSession

export const query = graphql`
  query requestSessionPageQuery {
    contentfulPage(slug: { eq: "request-a-session" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
