/** @jsx jsx */
import { jsx } from "theme-ui"
import kebabCase from "lodash/kebabCase"
import get from "lodash/get"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Column from "../components/column"
import Container from "../components/container"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"

const headquartersModule = ({
  bladePatternData,
  size,
  containerPadding,
  justify,
  imgheight,
}) => {           
  const contentCards = bladePatternData.contentCards
  const image = bladePatternData.desktopImage
  return (
    <Container customStyle={{ pl: containerPadding }}>
      <Row
        customStyle={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
        }}
      >
        <Column
          size={size ? [12, 12, size[0]] : [12, 12, 7]}  
          customStyle={{
            mb: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              0,
            ],
          }}
        >
          <div>
            {image && image.file && image.file.url && (
              <img
                alt={image.title || ""}
                sx={{ width: "100%", height: imgheight ? imgheight : "auto" }}
                src={image.file.url}
              />
            )}
          </div>
        </Column>
        <Column
          size={size ? [12, 12, size[1]] : [12, 12, 5]}
          customStyle={{
            display: "flex",
            // alignItems: "center",
            // justifyContent: justify ? justify : "center",
            pl: theme => [10, 10, theme.spacing.vertical.md, 70],
          }}
        >
          {contentCards.length &&
            contentCards.map(card => {
              return (
                <div
                  key={kebabCase(card.title)}
                  sx={{ width: "100%", alignSelf: "flex-start" }}
                >
                  <Text type="h4">{card.title}</Text>
                  {card.description &&
                    documentToReactComponents(card.description.json, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <Text
                            customStyle={{
                              color: "#999",
                              mt: 0,
                              fontSize:17
                            }}
                          >
                            {children}
                          </Text>
                        ),
                      },
                    })}
                  {card.ctaTitle && (
                    <PageLink
                      customStyle={{ color: "primary", my: 3 }}
                      link={
                        get(
                          card,
                          "ctaDestinationExternal.ctaDestinationExternal"
                        ) ||
                        get(card, "ctaDestination.ctaDestination") ||
                        "/"
                      }
                      text={`${card.ctaTitle} >`}
                      type={
                        get(
                          card,
                          "ctaDestinationExternal.ctaDestinationExternal"
                        )
                          ? "external"
                          : "internal"
                      }
                    />
                  )}
                  <br/>
                </div>
              )
            })}
        </Column>
      </Row>
    </Container>
  )
}

export default headquartersModule
